import { useStore } from 'effector-react';
import { Toggle } from '../../services/featureToggle/model/FeatureToggleV2';
import { useFeatureToggleServiceV2 } from '../useFeatureToggleServiceV2/useFeatureToggleServiceV2';

export function useStoredToggleV2(featureName: string, projectName: string): Toggle | undefined {
  const service = useFeatureToggleServiceV2(projectName);
  const state = useStore(service.getStore());

  return state[featureName] || undefined;
}
