import countries from './mocks/countries.json';
import states from './mocks/states.json';
import { Country, State } from './model/Regions';
import RegionService from './RegionService';

class RegionServiceImp implements RegionService {
  public getCountries(): Array<Country> {
    return countries;
  }

  public getStateByCountry(country: string): Array<State> {
    return states[country];
  }
}

export default RegionServiceImp;
