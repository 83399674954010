import { useStoreMap } from 'effector-react';
import { useMemo } from 'react';
import { usePermissionService } from '../useService/UseService';

export function useHasPermission(
  scopes: string | Array<string>,
  mode: 'AND' | 'OR' = 'AND'
): boolean {
  const permissionService = usePermissionService();
  const scopesFromStore: Array<string> = useStoreMap({
    store: permissionService.getStore(),
    keys: [scopes],
    fn: (state: { scopes: Array<string> }) => state.scopes
  });

  const hasPermission = useMemo(() => {
    if (Array.isArray(scopes) && scopes.length === 0) return true;
    return mode === 'AND'
      ? permissionService.hasScopes(scopesFromStore, scopes)
      : permissionService.hasOneOfScopes(scopes);
  }, [scopesFromStore, scopes, mode]);

  return hasPermission;
}
