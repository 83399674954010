import * as PubSub from 'pubsub-js';
import { v4 as uuidv4 } from 'uuid';
import Toast, { ToastSubscription } from './model/Toast';
import ToastService from './ToastService';

class ToastServiceImp implements ToastService {
  private TOPIC_NAME = 'TOAST';
  public notify(toast: Toast): void {
    PubSub.publish(this.TOPIC_NAME, toast);
  }
  public subscribe(callback: (toast: ToastSubscription) => void): any {
    return PubSub.subscribe(this.TOPIC_NAME, (_: string, data: ToastSubscription) =>
      callback({ ...data, id: uuidv4() })
    );
  }
  public unsubscribe(callback: (toast: ToastSubscription) => void): void {
    PubSub.unsubscribe(callback);
  }
}

export default ToastServiceImp;
