import axios from 'axios';
import { useCallback } from 'react';
import { useAuthenticationService } from '../../hooks/useService/UseService';
import { PostProps } from './useAuditLog.types';

const initialAxios = axios.create();
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

const API_URL = '/api/v1/admin-portal-shared-bff/audit-log';

authentication.enhancedAxios(initialAxios);

export function useAuditLog(appId: string) {
  const post = useCallback(
    async ({ metadata, operation, entity, entityId, country }: PostProps) => {
      return await initialAxios.post(API_URL, {
        action: {
          metadata,
          operation
        },
        doneBy: {
          appId
        },
        target: {
          entity,
          entityId
        }
      }, {
        headers: {
          country: country ?? userCountry
        }
      });
    },
    [appId]
  );

  return post;
}
