import { useMemo } from 'react';
import { ClusterMap } from '../../data/cluster_map';

export const useGetCluster = () => {
  const url = window.location.hostname;
  return useMemo(() => {
    const cluster = ClusterMap.get(url);
    return cluster;
  }, [url]);
};
