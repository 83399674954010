interface DictionaryType {
  language: string;
  countryName: string;
}

const languageDictionary: {
  [key: string]: DictionaryType;
} = {
  ZA: { language: 'en-ZA', countryName: 'English (South Africa)' },
  AE: { language: 'ar-AE', countryName: 'Arabic (U.A.E.)' },
  BH: { language: 'ar-BH', countryName: 'Arabic (Bahrain)' },
  DZ: { language: 'ar-DZ', countryName: 'Arabic (Algeria)' },
  EG: { language: 'ar-EG', countryName: 'Arabic (Egypt)' },
  IQ: { language: 'ar-IQ', countryName: 'Arabic (Iraq)' },
  JO: { language: 'ar-JO', countryName: 'Arabic (Jordan)' },
  KW: { language: 'ar-KW', countryName: 'Arabic (Kuwait)' },
  LB: { language: 'ar-LB', countryName: 'Arabic (Lebanon)' },
  LY: { language: 'ar-LY', countryName: 'Arabic (Libya)' },
  MA: { language: 'ar-MA', countryName: 'Arabic (Morocco)' },
  OM: { language: 'ar-OM', countryName: 'Arabic (Oman)' },
  QA: { language: 'ar-QA', countryName: 'Arabic (Qatar)' },
  SA: { language: 'ar-SA', countryName: 'Arabic (Saudi Arabia)' },
  SY: { language: 'ar-SY', countryName: 'Arabic (Syria)' },
  TN: { language: 'ar-TN', countryName: 'Arabic (Tunisia)' },
  YE: { language: 'ar-YE', countryName: 'Arabic (Yemen)' },
  AZ: { language: 'az-AZ', countryName: 'Azeri (Latin) (Azerbaijan)' },
  BY: { language: 'be-BY', countryName: 'Belarusian (Belarus)' },
  BG: { language: 'bg-BG', countryName: 'Bulgarian (Bulgaria)' },
  BA: { language: 'bs-BA', countryName: 'Bosnian (Bosnia and Herzegovina)' },
  ES: { language: 'ca-ES', countryName: 'Catalan (Spain)' },
  CZ: { language: 'cs-CZ', countryName: 'Czech (Czech Republic)' },
  GB: { language: 'cy-GB', countryName: 'Welsh (United Kingdom)' },
  DK: { language: 'da-DK', countryName: 'Danish (Denmark)' },
  AT: { language: 'de-AT', countryName: 'German (Austria)' },
  CH: { language: 'de-CH', countryName: 'German (Switzerland)' },
  DE: { language: 'de-DE', countryName: 'German (Germany)' },
  LI: { language: 'de-LI', countryName: 'German (Liechtenstein)' },
  LU: { language: 'de-LU', countryName: 'German (Luxembourg)' },
  MV: { language: 'dv-MV', countryName: 'Divehi (Maldives)' },
  GR: { language: 'el-GR', countryName: 'Greek (Greece)' },
  AU: { language: 'en-AU', countryName: 'English (Australia)' },
  BZ: { language: 'en-BZ', countryName: 'English (Belize)' },
  CA: { language: 'en-CA', countryName: 'English (Canada)' },
  CB: { language: 'en-CB', countryName: 'English (Caribbean)' },
  IE: { language: 'en-IE', countryName: 'English (Ireland)' },
  JM: { language: 'en-JM', countryName: 'English (Jamaica)' },
  NZ: { language: 'en-NZ', countryName: 'English (New Zealand)' },
  PH: { language: 'en-PH', countryName: 'English (Republic of the Philippines)' },
  TT: { language: 'en-TT', countryName: 'English (Trinidad and Tobago)' },
  US: { language: 'en-US', countryName: 'English (United States)' },
  ZW: { language: 'en-ZW', countryName: 'English (Zimbabwe)' },
  AR: { language: 'es-AR', countryName: 'Spanish (Argentina)' },
  BO: { language: 'es-BO', countryName: 'Spanish (Bolivia)' },
  CL: { language: 'es-CL', countryName: 'Spanish (Chile)' },
  CO: { language: 'es-CO', countryName: 'Spanish (Colombia)' },
  CR: { language: 'es-CR', countryName: 'Spanish (Costa Rica)' },
  DO: { language: 'es-DO', countryName: 'Spanish (Dominican Republic)' },
  EC: { language: 'es-EC', countryName: 'Spanish (Ecuador)' },
  GT: { language: 'es-GT', countryName: 'Spanish (Guatemala)' },
  HN: { language: 'es-HN', countryName: 'Spanish (Honduras)' },
  MX: { language: 'es-MX', countryName: 'Spanish (Mexico)' },
  NI: { language: 'es-NI', countryName: 'Spanish (Nicaragua)' },
  PA: { language: 'es-PA', countryName: 'Spanish (Panama)' },
  PE: { language: 'es-PE', countryName: 'Spanish (Peru)' },
  PR: { language: 'es-PR', countryName: 'Spanish (Puerto Rico)' },
  PY: { language: 'es-PY', countryName: 'Spanish (Paraguay)' },
  SV: { language: 'es-SV', countryName: 'Spanish (El Salvador)' },
  UY: { language: 'es-UY', countryName: 'Spanish (Uruguay)' },
  VE: { language: 'es-VE', countryName: 'Spanish (Venezuela)' },
  EE: { language: 'et-EE', countryName: 'Estonian (Estonia)' },
  IR: { language: 'fa-IR', countryName: 'Farsi (Iran)' },
  FI: { language: 'fi-FI', countryName: 'Finnish (Finland)' },
  FO: { language: 'fo-FO', countryName: 'Faroese (Faroe Islands)' },
  BE: { language: 'fr-BE', countryName: 'French (Belgium)' },
  FR: { language: 'fr-FR', countryName: 'French (France)' },
  MC: { language: 'fr-MC', countryName: 'French (Principality of Monaco)' },
  IN: { language: 'gu-IN', countryName: 'Gujarati (India)' },
  IL: { language: 'he-IL', countryName: 'Hebrew (Israel)' },
  HR: { language: 'hr-HR', countryName: 'Croatian (Croatia)' },
  HU: { language: 'hu-HU', countryName: 'Hungarian (Hungary)' },
  AM: { language: 'hy-AM', countryName: 'Armenian (Armenia)' },
  ID: { language: 'id-ID', countryName: 'Indonesian (Indonesia)' },
  IS: { language: 'is-IS', countryName: 'Icelandic (Iceland)' },
  IT: { language: 'it-IT', countryName: 'Italian (Italy)' },
  JP: { language: 'ja-JP', countryName: 'Japanese (Japan)' },
  GE: { language: 'ka-GE', countryName: 'Georgian (Georgia)' },
  KZ: { language: 'kk-KZ', countryName: 'Kazakh (Kazakhstan)' },
  KR: { language: 'ko-KR', countryName: 'Korean (Korea)' },
  KG: { language: 'ky-KG', countryName: 'Kyrgyz (Kyrgyzstan)' },
  LT: { language: 'lt-LT', countryName: 'Lithuanian (Lithuania)' },
  LV: { language: 'lv-LV', countryName: 'Latvian (Latvia)' },
  MK: { language: 'mk-MK', countryName: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)' },
  MN: { language: 'mn-MN', countryName: 'Mongolian (Mongolia)' },
  BN: { language: 'ms-BN', countryName: 'Malay (Brunei Darussalam)' },
  MY: { language: 'ms-MY', countryName: 'Malay (Malaysia)' },
  MT: { language: 'mt-MT', countryName: 'Maltese (Malta)' },
  NO: { language: 'nb-NO', countryName: 'Norwegian (Bokm?l) (Norway)' },
  NL: { language: 'nl-NL', countryName: 'Dutch (Netherlands)' },
  PL: { language: 'pl-PL', countryName: 'Polish (Poland)' },
  BR: { language: 'pt-BR', countryName: 'Portuguese (Brazil)' },
  PT: { language: 'pt-PT', countryName: 'Portuguese (Portugal)' },
  RO: { language: 'ro-RO', countryName: 'Romanian (Romania)' },
  RU: { language: 'ru-RU', countryName: 'Russian (Russia)' },
  SE: { language: 'se-SE', countryName: 'Sami (Sweden)' },
  SK: { language: 'sk-SK', countryName: 'Slovak (Slovakia)' },
  SI: { language: 'sl-SI', countryName: 'Slovenian (Slovenia)' },
  AL: { language: 'sq-AL', countryName: 'Albanian (Albania)' },
  SP: { language: 'sr-SP', countryName: 'Serbian (Latin) (Serbia and Montenegro)' },
  KE: { language: 'sw-KE', countryName: 'Swahili (Kenya)' },
  TH: { language: 'th-TH', countryName: 'Thai (Thailand)' },
  TR: { language: 'tr-TR', countryName: 'Turkish (Turkey)' },
  UA: { language: 'uk-UA', countryName: 'Ukrainian (Ukraine)' },
  PK: { language: 'ur-PK', countryName: 'Urdu (Islamic Republic of Pakistan)' },
  UZ: { language: 'uz-UZ', countryName: 'Uzbek (Latin) (Uzbekistan)' },
  VN: { language: 'vi-VN', countryName: 'Vietnamese (Viet Nam)' },
  CN: { language: 'zh-CN', countryName: 'Chinese (S)' },
  HK: { language: 'zh-HK', countryName: 'Chinese (Hong Kong)' },
  MO: { language: 'zh-MO', countryName: 'Chinese (Macau)' },
  SG: { language: 'zh-SG', countryName: 'Chinese (Singapore)' },
  TW: { language: 'zh-TW', countryName: 'Chinese (T)' }
};

export default languageDictionary;
