import { useAuthenticationService } from '../../hooks/useService/UseService';
import { useUserMetadata } from '../../services/userMetadata/useUserMetadata';

const defaultLanguage = localStorage.getItem('language') ?? 'en-US';

const availableLanguages = {
  en: 'en-US',
  es: 'es-419',
  pt: 'pt-BR'
};

/**
 * @deprecated This hook is deprecated, consider update to usePreferredLanguageV2: {@link http://ab-inbev.atlassian.net/wiki/spaces/SP/pages/3323855423/usePreferredLanguageV2}
 */

export default function usePreferredLanguage() {
  const { data, isLoading, hasError } = useUserMetadata();
  const { language } = useAuthenticationService().getUserCountryAndLanguage();

  const userDefaultLanguage = availableLanguages[language?.split('-')[0]] ?? defaultLanguage;

  console.warn('This hook is deprecated! Please use usePreferredLanguageV2 instead.');

  if (isLoading || hasError) {
    return userDefaultLanguage;
  }

  const preferredLanguage = data?.profile?.preferredLanguage;

  return preferredLanguage ?? userDefaultLanguage;
}
