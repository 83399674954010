import { createStore } from 'effector';
import Scope from '../../services/permission/models/Scope';
import { setScopes } from './PermissionEvents';

const initialState = {
  scopes: []
};

const permissionStore = createStore<Scope>(initialState);

permissionStore.on(setScopes, (state, scopes) => {
  const newState = { ...state };
  newState.scopes = [...newState.scopes, ...scopes];

  return newState;
});

export default permissionStore;
