export const ClusterMap = new Map();

// Bees Hub Portal
// SIT
ClusterMap.set('one-sit.bees-platform.dev', 'Global_US');
// UAT
ClusterMap.set('one-uat.bees-platform.dev', 'Global_US');
ClusterMap.set('hub-uat-us.bees-platform.dev', 'Restricted_US');
ClusterMap.set('one-uat-eu.bees-platform.dev', 'Global_EU');
// PROD
ClusterMap.set('one.bees.com', 'Global_US');
ClusterMap.set('one.bees-platform.com', 'Global_US');
ClusterMap.set('one-us.bees.com', 'Restricted_US');
ClusterMap.set('one-eu.bees.com', 'Global_EU');

// DTC Portal
// SIT
ClusterMap.set('hub-dtc-sit.bees-platform.dev', 'Global_DTC');
// UAT
ClusterMap.set('hub-dtc-uat.bees-platform.dev', 'Global_DTC');
// PROD
ClusterMap.set('one-dtc.bees.com', 'Global_DTC');
