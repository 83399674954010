import { useCallback, useEffect, useRef, useState } from 'react';
import { useFeatureToggleServiceV2 } from '../useFeatureToggleServiceV2/useFeatureToggleServiceV2';
import { useFeatureToggleV2Actions } from './useFeatureToggleV2Actions';
import { useStoredToggleV2 } from './useStoredToggleV2';

type Response = [boolean | undefined, boolean];

export function useFeatureToggleV2(featureName: string, projectName: string): Response {
  const service = useFeatureToggleServiceV2(projectName);
  const isReady = service.getIsReady();

  const isFirstLoad = useRef(true);
  const [response, setResponse] = useState<Response>([undefined, true]);
  const currentStoredToggle = useStoredToggleV2(featureName, projectName);
  const { isFeatureToggleV2Enabled, isToggleInfoAvailable } = useFeatureToggleV2Actions(
    featureName,
    projectName
  );

  const isToggleDifferentFromTheStore = useCallback(
    (status?: boolean): boolean => {
      if (!currentStoredToggle) {
        return false;
      }
      const toggleInfo = {
        userId: currentStoredToggle.userId,
        country: currentStoredToggle.country
      };
      return isToggleInfoAvailable(toggleInfo) && status !== currentStoredToggle.value;
    },
    [currentStoredToggle, isToggleInfoAvailable]
  );

  const initToggleStatus = useCallback(async () => {
    const status = await isFeatureToggleV2Enabled();
    setResponse([status, false]);
  }, [isFeatureToggleV2Enabled]);

  const updateToggleStatus = useCallback(() => {
    const [status, isLoading] = response;
    if (isLoading === false && isToggleDifferentFromTheStore(status)) {
      setResponse([currentStoredToggle!.value, false]);
    }
  }, [currentStoredToggle, isToggleDifferentFromTheStore, response]);

  useEffect(() => {
    if (isReady === true) {
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
        initToggleStatus();
      } else {
        updateToggleStatus();
      }
    }
  }, [initToggleStatus, updateToggleStatus, isReady]);

  return response;
}
