import EnvService from './EnvService';
import Env from './model/Env';
import SegmentKey from './model/SegmentKey';

const OPTIMIZELY_KEY = 'OPTIMIZELY_KEY';
const ENV = 'ENV';

class EnvServiceImp implements EnvService {
  public getOptimizelyKey(): string {
    return this.getEnvConfig()[OPTIMIZELY_KEY] || 'localhost';
  }

  public getSegmentKey(microFront: SegmentKey): string {
    return this.getEnvConfig()[microFront] || 'localhost';
  }

  public getEnvConfig(): { [key: string]: string } {
    return JSON.parse(localStorage.getItem('portal-config') || '{}');
  }

  public getEnv(): Env {
    return Env[(this.getEnvConfig()[ENV] || 'localhost').toUpperCase()];
  }
}
export default EnvServiceImp;
