import { ClusterMap } from "../../data/cluster_map";
import ClusterService from "./ClusterService";

class ClusterServiceImp implements ClusterService {
  public getCluster(): string {
    const url = window.location.hostname;
    return ClusterMap.get(url);

  }
}
export default ClusterServiceImp;
