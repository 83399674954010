import axios from 'axios';
import { UserMetadata } from '../../..';
import API_URLS from '../../../constants/API_URLS';
import { useAuthenticationService } from '../../../hooks/useService/UseService';

export const fetchUserMetadata = async (
  selectedCountry: string
): Promise<UserMetadata | undefined> => {
  const initialAxios = axios.create();
  const authentication = useAuthenticationService();
  authentication.enhancedAxios(initialAxios, {
    headers: [
      {
        country: selectedCountry
      }
    ]
  });
  try {
    const { data } = await initialAxios.get<UserMetadata>(API_URLS.USER_METADATA);
    return data;
  } catch (e) {
    console.error('There was an error fetching the user metadata');
  }
};
