import optimizelySDK, { UserAttributes } from '@optimizely/optimizely-sdk';
import { getFeatureToggleEvents } from '../../../store/FeatureToggleV2/FeatureToggleEventsV2';
import { createFeatureToggleStoreWithDynamicEvents } from '../../../store/FeatureToggleV2/FeatureToggleStoreV2';
import { FeatureToggle } from '../model/FeatureToggleV2';
import FeatureToggleServiceV2 from './FeatureToggleServiceV2';

const MOCKED_FEATURE_TOGGLES = 'mocked-feature-toggles';
class FeatureToggleOptmizelyServiceV2Imp implements FeatureToggleServiceV2 {
  private optimizely: optimizelySDK.Client;
  private store;
  private events = getFeatureToggleEvents();
  private isReady = false;

  public constructor(sdkKey?: string) {
    this.optimizely = optimizelySDK.createInstance({
      sdkKey: sdkKey,
    });

    this.store = createFeatureToggleStoreWithDynamicEvents(this.events);
    this.getMockedFeatureToggles();
    this.initOptimizely();
  }

  public async initOptimizely() {
    await this.optimizely.onReady();
    this.isReady = true;
  }

  public getStore() {
    return this.store;
  }

  public async isFeatureEnabledAudiences(
    featureToggleName: string,
    userId: string,
    attributes?: UserAttributes
  ): Promise<boolean> {
    const toggleValue = await this.optimizely.isFeatureEnabled(
      featureToggleName,
      userId,
      attributes
    );

    this.addToggle({
      name: featureToggleName,
      value: toggleValue,
      userId,
      country: attributes?.country ?? ''
    });

    return toggleValue;
  }

  public async onReady() {
    return this.optimizely.onReady();
  }

  public getIsReady() {
    return this.isReady;
  }

  public getEvents() {
    return this.events;
  }

  private addToggle(featureToggle: FeatureToggle) {
    this.events.setToggle(featureToggle);
  }

  public mockFeatureToggle(featureToggle: FeatureToggle) {
    this.addToggle(featureToggle);
  }

  private getMockedFeatureToggles() {
    const mockedFeatures = JSON.parse(window.localStorage.getItem(MOCKED_FEATURE_TOGGLES) || '{}');
    Object.keys(mockedFeatures).forEach((key: string) => {
      const toggle = mockedFeatures[key];
      this.addToggle({
        name: key,
        value: toggle.value,
        country: toggle.country,
        userId: toggle.userId
      });
    });
  }
}

export default FeatureToggleOptmizelyServiceV2Imp;
