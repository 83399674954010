import { createApi, createEffect, createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { z } from 'zod';
import { AppHeaderConfigPayloadSchema, CountrySchema } from './headerConfig.schema';
import { AppHeaderConfig, AppHeaderConfigPayload, Country, Vendor } from './headerConfig.types';
import { fetchVendors, getCountriesIntersection, sortString } from './utils';

interface SPABeforeRoutingEvent extends EventListener {
  detail: {
    newUrl: string;
  };
}
interface SPABeforeAppChangeEvent extends EventListener {
  detail: {
    appsByNewStatus: { MOUNTED: string[] };
  };
}

export const reset = createEvent();

// Store
const $appHeaderConfig = createStore<AppHeaderConfig>({
  countryOptions: [],
  vendorOptions: [],
  selectedCountry: undefined,
  selectedVendor: undefined,
  defaultCountry: undefined,
  defaultVendor: undefined,
  breadcrumbConfig: undefined,
  optionalButtons: [],
  loadingVendors: false,
  countrySelect: false,
  vendorSelect: false,
  pageTitle: ''
}).reset(reset);

window.addEventListener('single-spa:before-app-change', e => {
  const evt = (e as unknown) as SPABeforeAppChangeEvent;
  const mountedApps = evt.detail.appsByNewStatus.MOUNTED;
  const noRouteChanges = mountedApps.length === 0;
  if (!noRouteChanges) reset();
});

window.addEventListener('single-spa:before-routing-event', e => {
  const evt = (e as unknown) as SPABeforeRoutingEvent;
  const url = new URL(evt.detail.newUrl);
  const isMainMfe = url.pathname === '/';
  if (isMainMfe) reset();
});

const prepareCountriesForUpdate = (newCountries: Country[]) => {
  return newCountries.sort((a, b) => sortString(a, b));
};

// Effects API creation
const { setSelectedCountry, updateStore, setLoadingVendors, setSelectedVendor } = createApi(
  $appHeaderConfig,
  {
    setSelectedCountry: (prev, value: Country) =>
      produce(prev, draftState => {
        draftState.selectedCountry = value;
      }),
    setSelectedVendor: (prev, value: string | undefined) =>
      produce(prev, draftState => {
        draftState.selectedVendor = value;
      }),
    setLoadingVendors: (prev, value: boolean) =>
      produce(prev, draftState => {
        draftState.loadingVendors = value;
      }),
    updateStore: (prev, updatedValues: AppHeaderConfigPayload) =>
      produce(prev, draftState => {
        const keysToUpdate = Object.keys(updatedValues);
        keysToUpdate.forEach(async key => {
          const updatableKeys = [
            'breadcrumbConfig',
            'countryOptions',
            'countrySelect',
            'vendorSelect',
            'optionalButtons',
            'defaultCountry',
            'defaultVendor',
            'pageTitle'
          ];

          if (key === 'countryOptions') {
            const newCountries = updatedValues[key] as Country[];
            draftState.countryOptions = newCountries;
            await fetchCountriesFx(newCountries);
            return;
          }

          if (updatableKeys.includes(key)) {
            draftState[key] = updatedValues[key];
          }
        });
      })
  }
);

// Country fetching logic
const fetchCountriesFx = createEffect<Country[], Country[]>({
  name: 'fetch country data fx',
  async handler(params: Country[]): Promise<Country[]> {
    const data = await getCountriesIntersection(params);
    return data;
  }
});

$appHeaderConfig.on(fetchCountriesFx.doneData, (prev, data) => {
  const sortedCountries = prepareCountriesForUpdate(data);
  return produce(prev, draftState => {
    draftState.countryOptions = sortedCountries;
  });
});

// Vendor fetching logic
const fetchVendorsFx = createEffect<Country, Vendor[]>({
  name: 'fetch data fx',
  async handler(params: Country): Promise<Vendor[]> {
    const data = await fetchVendors(params);
    return data;
  }
});

const defaultVendor = $appHeaderConfig.map(state => state.defaultVendor);
$appHeaderConfig.on(fetchVendorsFx.doneData, (prev, data) => {
  const sortedVendors = data.sort((a, b) => sortString(a.displayName, b.displayName));
  const vendorsBySelectedCountry = sortedVendors.filter(
    vendor => vendor.country === prev.selectedCountry
  );
  const defaultVendorSelected = defaultVendor.getState();
  return produce(prev, draftState => {
    draftState.vendorOptions = vendorsBySelectedCountry;
    const isDefaultVendorAvailable =
      defaultVendorSelected &&
      vendorsBySelectedCountry.some(vendor => vendor.id === defaultVendorSelected);

    if (isDefaultVendorAvailable) draftState.selectedVendor = defaultVendorSelected;
    else if (vendorsBySelectedCountry[0]?.id)
      draftState.selectedVendor = vendorsBySelectedCountry[0].id;
  });
});

// Watching for changes in country options and selecting the first one available
const $countries = $appHeaderConfig.map(state => state.countryOptions);
const defaultCountry = $appHeaderConfig.map(state => state.defaultCountry);
$countries.watch(countries => {
  const defaultCountrySelected = defaultCountry.getState();

  if (defaultCountrySelected) {
    if (countries.includes(defaultCountrySelected)) {
      return setSelectedCountry(defaultCountrySelected);
    }

    // tslint:disable-next-line:no-console
    console.warn(`This user doesn't have access to country: ${defaultCountrySelected}`);
  }

  if (countries?.[0]) {
    return setSelectedCountry(countries[0]);
  }
});

// Watching for changes in selectedCountry and fetching vendors if country selected
$appHeaderConfig.watch(setSelectedCountry, async (_, data) => {
  setSelectedVendor(undefined);
  await fetchVendorsFx(data);
});

fetchVendorsFx.pending.watch(p => setLoadingVendors(p));

const validateSchema = (schema: z.Schema, payload: unknown, optionalPath?: string) => {
  const result = schema.safeParse(payload);
  if (result.success) return true;

  const { issues } = result.error;

  issues.forEach(issue => {
    console.error(
      issue.code === 'invalid_type'
        ? `[headerConfig]: Was expecting ${issue.expected}, but found ${
            issue.received
          } in ${optionalPath || issue.path.join('')}`
        : issue.message
    );
  });
  return false;
};

const onChangeSelectedCountry = (payload: Country) => {
  const isValid = validateSchema(CountrySchema, payload, 'selectedCountry');
  if (!isValid) return;
  setSelectedCountry(payload);
};

const onChangeSelectedVendor = (payload: string) => {
  const isValid = validateSchema(z.string(), payload, 'selectedVendor');
  if (!isValid) return;

  const isVendorFromList = $appHeaderConfig
    .getState()
    ?.vendorOptions?.find(vendor => vendor.id === payload);

  // istanbul ignore next
  if (!isVendorFromList) {
    console.error('Selected vendor was not found in available vendors list');
    return;
  }
  // istanbul ignore next
  setSelectedVendor(payload);
};

const requestUpdate = (config: AppHeaderConfigPayload) => {
  const isValid = validateSchema(AppHeaderConfigPayloadSchema, config);
  if (!isValid) return;
  updateStore(config);
};

const appHeaderConfig = {
  store: $appHeaderConfig,
  onChangeSelectedCountry,
  onChangeSelectedVendor
};

const useAppHeader = (): [AppHeaderConfig, typeof requestUpdate] => {
  const store = useStore($appHeaderConfig);
  return [store, requestUpdate];
};

export { appHeaderConfig, useAppHeader, requestUpdate as setAppHeaderConfig };
