import { useEffect } from 'react';
import { restoreToDefault, setSidebar } from '../../store/Sidebar/SidebarService';
import { Sidebar } from '../../store/Sidebar/SidebarService.types';
import { validateSidebarProps } from './helpers';

export const useSidebar = (sidebar: Sidebar) => {
  useEffect(() => {
    const isValidSidebarProps = validateSidebarProps(sidebar);

    if (isValidSidebarProps) {
      setSidebar(sidebar);
    }

    return () => {
      restoreToDefault();
    };
  }, [sidebar]);
};
