import React, { createContext, useContext } from 'react';
import { PropsConfig } from './types';

const defaultInitialValue: PropsConfig = {};

export const Context = createContext(defaultInitialValue);

export const usePropPerClusterContext = () => useContext(Context);

export const PropsPerClusterProvider = ({ propValues, children }: { propValues: PropsConfig; children: React.ReactNode }) => (
  <Context.Provider value={propValues}>{children}</Context.Provider>
);