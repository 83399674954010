import AnalyticsService from '../../services/analytics/AnalyticsService';
import AnalyticsServiceImp from '../../services/analytics/AnalyticsServiceImp';
import AuthenticationService from '../../services/authentication/AuthenticationService';
import AuthenticationServiceImp from '../../services/authentication/AuthenticationServiceImp';
import BusinessModelService from '../../services/businessModel/BusinessModelService';
import BusinessModelServiceImp from '../../services/businessModel/BusinessModelServiceImp';
import ClusterService from '../../services/cluster/ClusterService';
import ClusterServiceImp from '../../services/cluster/ClusterServiceImp';
import EnvService from '../../services/env/EnvService';
import EnvServiceImp from '../../services/env/EnvServiceImp';
import FeatureToggleOptmizelyServiceImp from '../../services/featureToggle/FeatureToggleOptmizelyServiceImp';
import FeatureToggleService from '../../services/featureToggle/FeatureToggleService';
import LogService from '../../services/log/LogService';
import LogServiceImp from '../../services/log/LogServiceImp';
import PermissionService from '../../services/permission/PermissionService';
import PermissionServiceImp from '../../services/permission/PermissionServiceImp';
import RegionService from '../../services/region/RegionService';
import RegionServiceImp from '../../services/region/RegionServiceImp';
import SnackbarService from '../../services/snackbar/SnackbarService';
import SnackbarServiceImp from '../../services/snackbar/SnackbarServiceImp';
import ToastService from '../../services/toast/ToastService';
import ToastServiceImp from '../../services/toast/ToastServiceImp';

declare global {
  interface Window {
    services: {
      [key: string]: {};
    };
  }
}

export function useAuthenticationService(): AuthenticationService {
  return useService<AuthenticationService>(
    'Authentication',
    AuthenticationServiceImp
  ) as AuthenticationService;
}

export function useFeatureToggleService(): FeatureToggleService {
  return useService<FeatureToggleService>(
    'FeatureToggleOptimizely',
    FeatureToggleOptmizelyServiceImp
  ) as FeatureToggleService;
}

export function useLogService(): LogService {
  return useService<LogService>('Log', LogServiceImp) as LogService;
}

export function useToast(): ToastService {
  return useService<ToastService>('Toast', ToastServiceImp) as ToastService;
}

export function useSnackbar(): SnackbarService {
  return useService<SnackbarService>('Snackbar', SnackbarServiceImp) as SnackbarService;
}

export function useRegionService(): RegionService {
  return useService<RegionService>('Region', RegionServiceImp) as RegionService;
}

export function useEnvService(): EnvService {
  return useService<EnvService>('Env', EnvServiceImp) as EnvService;
}

export function usePermissionService(): PermissionService {
  return useService<PermissionService>('Permission', PermissionServiceImp) as PermissionService;
}

export function useAnalyticsService(): AnalyticsService {
  return useService<AnalyticsService>('Analytics', AnalyticsServiceImp) as AnalyticsService;
}

export function useBusinessModelService(): BusinessModelService {
  return useService<BusinessModelService>(
    'BusinessModel',
    BusinessModelServiceImp
  ) as BusinessModelService;
}

export function useClusterService(): ClusterService {
  return useService<ClusterService>('Cluster', ClusterServiceImp) as ClusterService;
}

function useService<T extends {}>(serviceName: string, ServiceImplementation: new () => T): {} {
  if (!window.services) {
    window.services = {};
  }

  if (!window.services[serviceName]) {
    window.services[serviceName] = new ServiceImplementation();
  }

  return window.services[serviceName];
}
