import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { useFeatureToggleService } from '../useService/UseService';

const hasAValidName = (featureName?: string): boolean =>
  !!(featureName && featureName.trim() !== '');

export function useFeatureToggleVerify() {
  const features = useStore(useFeatureToggleService().getStore());

  const verifyToggleV1 = useCallback(
    (featureName: string, zone?: string): boolean => {
      if (!hasAValidName(featureName)) {
        return false;
      }
      const featureToggle = features[featureName];
      if (typeof featureToggle === 'string' && zone) {
        return featureToggle.includes(zone);
      }
      return featureToggle === true;
    },
    [features]
  );

  return {
    verifyToggleV1
  };
}
