import { useMemo } from 'react';
import { useGetCluster } from '../useGetCluster/useGetCluster';
import { usePropPerClusterContext } from './provider';
import { ConfigValue, PropValue } from './types';

const DEFAULT_KEY = 'default';
export const DEFAULT_PROP_ERROR_MSG = '[useGetEnvPropPerCluster]: This environment prop does not have the mandatory `default` property.';

const isClustersObject = (object: ConfigValue) => {
  if (typeof object === 'object' && object.hasOwnProperty(DEFAULT_KEY)) {
    return true;
  }

  console.error(DEFAULT_PROP_ERROR_MSG)
  return false;
};

const isPrimitiveType = (value: any) => {
  const currentType = typeof value;
  if (currentType === 'string' || currentType === 'boolean' || currentType === 'number') {
    return true;
  }

  return false;
}

export const useGetEnvPropPerCluster = (propKey: string): PropValue | null => {
  const props = usePropPerClusterContext();
  const cluster = useGetCluster();

  const propOfCluster = useMemo(() => {
    if (props[propKey] && isClustersObject(props[propKey])){
      const defaultValue = props[propKey][DEFAULT_KEY];

      return props[propKey][cluster] || defaultValue;
    }
    else if (props[propKey] && isPrimitiveType(props[propKey])) {
      return props[propKey];
    } else {
      return null;
    }
  }, [props, cluster]);

  return propOfCluster;
}