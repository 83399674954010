import * as React from 'react';

const DEFAULT_OPTIONS = {
  deserialize: JSON.parse,
  serialize: JSON.stringify
};

type JSONPrimitive = string | number | boolean | null;
type JSONObject = { [k: string]: JSONValue };
type JSONArray = JSONValue[];
export type JSONValue = JSONArray | JSONObject | JSONPrimitive;

type InitialValue = JSONValue | (() => JSONValue);

export const useLocalStorage = (
  key: string,
  defaultValue?: InitialValue,
  options = DEFAULT_OPTIONS
) => {
  const { serialize, deserialize } = options;
  const [value, setValue] = React.useState(() => {
    const valueFromLocalStorage = localStorage.getItem(key);
    if (valueFromLocalStorage) return deserialize(valueFromLocalStorage);
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  const keyRef = React.useRef(key);

  React.useEffect(() => {
    const prevKey = keyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    keyRef.current = key;
    window.localStorage.setItem(key, serialize(value));
  }, [key, value, serialize]);

  return [value, setValue];
};
