import FeatureToggleServiceV2 from '../../services/featureToggle/featureToggleOptimizelyServiceV2/FeatureToggleServiceV2';

export function useFeatureToggleServiceV2(projectName: string): FeatureToggleServiceV2 {
  const FEATURE_NAME = 'FeatureToggleOptimizelyV2';

  if (!window.services[FEATURE_NAME]?.[projectName]) {
    console.error(
      `[FeatureToggleOptimizelyV2]: there is no project instance of ${projectName} created`
    );
  }

  return window.services[FEATURE_NAME][projectName] as FeatureToggleServiceV2;
}
