import BusinessModelService, { Abbreviation } from './BusinessModelService';

class BusinessModelServiceImp implements BusinessModelService {
  public getBusinessModel(businessModel: string, language?: Abbreviation): string {
    if (!language) {
      language = 'en-US';
    }

    // Keys are the Backend model
    // Values are the Frontend model
    const BUSINESS_MODELS = {
      'en-US': {
        '1P': '1P (1L)',
        '1P-3L': '1P (3L) Rewards',
        '3P-3L-SS': '3P (3L) Same store',
        '3P-3L-SF': '3P (3L) Storefront',
        '3P-1L': '3P (1L)'
      },
      'pt-BR': {
        '1P': '1P (1L)',
        '1P-3L': '1P (3L) Pontos',
        '3P-3L-SS': '3P (3L) Loja do BEES',
        '3P-3L-SF': '3P (3L) Loja da Empresa Parceira',
        '3P-1L': '3P (1L)'
      },
      'es-419': {
        '1P': '1P (1L)',
        '1P-3L': '1P (3L) Recompensas',
        '3P-3L-SS': '3P (3L) Mismo Negocio',
        '3P-3L-SF': '3P (3L) Frente Del Local',
        '3P-1L': '3P (1L)'
      }
    };
    const businessModelSelected = BUSINESS_MODELS[language] ? BUSINESS_MODELS[language][businessModel] : BUSINESS_MODELS['en-US'][businessModel]
    return businessModelSelected || businessModel;
  }
}
export default BusinessModelServiceImp;
