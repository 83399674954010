import axios from 'axios';
import useSwr from 'swr';
import { COUNTRY_SERVICE } from '../../constants/API_URLS';
import { useAuthenticationService } from '../../hooks/useService/UseService';
import { CountrySearch, CountryService } from './useCountryService.types';

const initialAxios = axios.create();

const fetcher = (apiPath: string) => initialAxios.get<CountryService>(apiPath).then(res => res.data);

const getApiUrl = (searchParams?: CountrySearch) => {
  if (!searchParams) return COUNTRY_SERVICE;

  const { id, searchBy } = searchParams;
  
  if (searchBy === 'cluster') return `${COUNTRY_SERVICE}/cluster/${id}`;
  return `${COUNTRY_SERVICE}/${id}`;
};

export const useCountryService = (searchParams?: CountrySearch) => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  authentication.enhancedAxios(initialAxios, {
    headers: [
      {
        country: userCountry ?? ''
      }
    ]
  });

  const authToken = authentication.getAuthHeader();

  const apiUrl = getApiUrl(searchParams);
  const { data, error, mutate } = useSwr(authToken ? apiUrl : null, fetcher, {
    dedupingInterval: 5000,
    focusThrottleInterval: 10000,
    revalidateOnFocus: false
  });

  return {
    data: data?.data,
    isLoading: !error && !data,
    hasError: Boolean(error),
    mutateCountryService: mutate
  };
};
