import axios, { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import jwtDecode from 'jwt-decode';
import * as uuid from 'uuid';
import { useEnvService, useLogService } from '../../hooks/useService/UseService';
import { decrypt } from '../../utils/crypto/crypto';
import isEmpty from '../../utils/isEmpty';
import languageDictionary from '../../utils/languageDictionary';
import AuthenticationService from './AuthenticationService';
import {
  CustomAxiosRequestConfig,
  EnhancedAxiosOptions,
  UserInfoToken
} from './AuthenticationService.types';
import AjsUserTraits from './model/AjsUserTraits';

const AUTH_HEADER_KEY = 'authHeader';
const AUTH_REFRESH_TOKEN_KEY = 'refresh_token';
const AUTH_TIMESTAMP = 'authTimestamp';
const PORTAL_CONFIG = 'portal-config';
const AJS_USER_TRAITS = 'ajs_user_traits';

class AuthenticationServiceImp implements AuthenticationService {
  constructor() {
    const authHeader = this.getAuthHeader();
    const bees_one_hash = localStorage.getItem('bees_one_hash');
    if (authHeader && !bees_one_hash) window.location.assign('/auth/login');
  }

  public getAuthHeader(): string | null {
    const authTimestamp = parseInt(window.localStorage.getItem(AUTH_TIMESTAMP) || '0', 10);
    const now = Date.now();
    const maxTokenLifespan = this.getMaxTokenLifespan();

    if (this.isToplineUser()) {
      return window.localStorage.getItem(AUTH_HEADER_KEY);
    }

    if (now - authTimestamp > maxTokenLifespan) {
      return null;
    }

    return window.localStorage.getItem(AUTH_HEADER_KEY);
  }

  public setAuthHeader(jwt: string) {
    window.localStorage.setItem(AUTH_HEADER_KEY, jwt);
    window.localStorage.setItem(AUTH_TIMESTAMP, Date.now().toString());
  }

  public setRefreshToken(token: string) {
    window.localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, token);
  }

  public getRoles(): Array<string> {
    const jwt = this.parseJwt();
    const roles = jwt?.roles;
    if (!Array.isArray(roles)) {
      return [];
    }
    return roles;
  }

  public getRolesByPrefix(prefix: string): Array<string> {
    const roles = this.getRoles();
    const filterRoles = roles.filter(role => role.includes(prefix.toUpperCase()));
    return filterRoles.length ? filterRoles : [];
  }

  public getUserScopes(): Array<string> {
    const bees_one_hash = localStorage.getItem('bees_one_hash');
    if (!bees_one_hash) return [];
    try {
      const scopes = decrypt(bees_one_hash);
      if (!Array.isArray(scopes)) {
        return [];
      }
      return scopes;
    } catch {
      return [];
    }
  }

  public getZonesByRoles(prefix: string): Array<string> {
    const roles = this.getRoles();
    const zones = new Set<string>();
    roles.forEach(role => {
      if (role.includes(prefix.toUpperCase())) {
        const country = role.split(`${prefix.toUpperCase()}_`)[1];
        country?.length === 2 && zones.add(country);
      }
    });

    return Array.from(zones);
  }

  public hasRole(role: string): boolean {
    return this.getRoles().includes(role);
  }

  public isToplineUser(): boolean {
    const ajsUser = window.localStorage.getItem(AJS_USER_TRAITS);

    const ajsUserAux: AjsUserTraits | null = ajsUser ? JSON.parse(ajsUser) : null;

    if (ajsUserAux?.DDC) {
      return ajsUserAux.DDC.includes('TOPLINE');
    }

    return false;
  }

  public getUsername(): string {
    return this.parseJwt()?.app ?? '';
  }
  
  public getUserId(): string {
    return this.parseJwt()?.sub ?? '';
  }

  public getUserFullNameB2C(): { given_name: string; family_name: string } {
    const parsedJwt = this.parseJwt();
    return {
      given_name: parsedJwt?.given_name ?? '',
      family_name: parsedJwt?.family_name ?? ''
    };
  }

  public getUserEmailB2C(): string {
    return this.parseJwt()?.email ?? '';
  }

  public getCountryB2C(): string {
    return this.parseJwt()?.country ?? '';
  }
  
  public getRoleB2C(): string {
    return this.parseJwt()?.role ?? '';
  }
  
  public getPreferredLanguageB2C(): string {
    return this.parseJwt()?.preferredLanguage ?? '';
  }

  public getLanguageByCountry(country: string) {
    const countryObj = languageDictionary[country];
    return countryObj?.language ?? 'en-US';
  }

  public getUserCountryAndLanguage() {
    const env = useEnvService().getEnv();
    const baseUrl = window.location.origin;
    const isAuthenticated = this.getAuthHeader();

    if (!isAuthenticated) {
      if (env === 'PROD') {
        const unstructuredUrl: Array<string> = baseUrl.split('.');
        const countryCode = unstructuredUrl[unstructuredUrl.length - 1].toUpperCase();

        return {
          user_country: null,
          language: this.getLanguageByCountry(countryCode),
          base_url: baseUrl
        };
      }
      return {
        user_country: null,
        language: this.getLanguageByCountry(localStorage.getItem('defaultLanguage') || 'US'),
        base_url: baseUrl
      };
    }

    const b2cCountry = this.getCountryB2C();
    const language = this.getLanguageByCountry(b2cCountry);

    return {
      user_country: b2cCountry,
      language: language,
      base_url: baseUrl
    };
  }

  public getJWTExpiration(): string | null {
    return this.parseJwt()?.exp ?? null;
  }

  public parseJwt(): UserInfoToken | null {
    const authHeader = this.getAuthHeader();
    try {
      if (authHeader === null) {
        return null;
      }
      return jwtDecode(authHeader.split('Bearer ')[1]);
    } catch (e) {
      return null;
    }
  }

  public unsetLocalStorageKeys(keys: Array<string>): void {
    keys.forEach(key => {
      window.localStorage.removeItem(key);
    });
  }

  public getUserDistributionCenter(): Array<string> {
    const filteredRole = this.getRolesByPrefix('CDD');
    if (filteredRole?.length) {
      const listOfCdd = filteredRole[0].split('_');
      listOfCdd.splice(0, 1);
      return listOfCdd;
    }
    return [];
  }

  public getUserRoleValue(prefix: string): string {
    const filteredRole = this.getRolesByPrefix(prefix);
    if (filteredRole?.length) {
      return filteredRole[0].split('_')[1];
    }
    return '';
  }

  public getUserLanguage(): string {
    return this.getUserRoleValue('LANGUAGE');
  }

  public getUserCountry(): string {
    return this.getUserRoleValue('COUNTRY');
  }

  public getIssuer(): string {
    return this.parseJwt()?.iss ?? '';
  }

  public refreshToken(token: string): Promise<AxiosStatic> {
    const location = window.location;
    const headers = { Authorization: this.getAuthHeader() };

    return axios
      .post<any, AxiosResponse>('/auth/token/refresh',
        { refreshToken: token },
        { headers }
      ).then(res => {
        if (res.status === 201 && res.data) {
          const { access_token, token_type, expires_in, refresh_token, id_token } = res.data;

          if (access_token && token_type && expires_in && refresh_token && id_token) {
            this.setAuthHeader(`${token_type} ${id_token}`);
            this.setRefreshToken(refresh_token);
            this.needAcceptTheTerm().then(response => {
              if (response) {
                location.href = `${location.origin}/update-terms-conditions`;
              }
            });
          }
        }

        return res;
      })
      .catch(err => err);
  }

  public enhancedAxios(
    axiosInstance: AxiosInstance,
    options?: EnhancedAxiosOptions
  ): AxiosInstance {
    const headers = (options?.headers) ?? [];
    const refresh_token = localStorage.getItem('refresh_token');
    const azure_auth = localStorage.getItem('azure_auth');
    const logService = useLogService();

    axiosInstance.interceptors.request.use(
      config => {
        config.headers = Object.assign(
          {},
          {
            Authorization: this.getAuthHeader(),
            requestTraceId: uuid.v4()
          },
          ...headers,
          config.headers
        );

        return config;
      },
      error => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        const originalRequest: CustomAxiosRequestConfig = error?.config || {};

        if (
          error.response &&
          error.response.status === 401 &&
          originalRequest?.url &&
          originalRequest.url.includes('/auth/token/refresh')
        ) {
          window.location.assign('/auth/login');
          return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest?.retry) {
          originalRequest.retry = true;

          if (!azure_auth || !refresh_token) {
            window.location.assign('/auth/login');
            return Promise.reject(error);
          }

          return this.refreshToken(refresh_token)
            .then(() => {
              originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: this.getAuthHeader()
              };
              return axiosInstance.request(originalRequest);
            })
            .catch(e => {
              logService.error(e);
              return Promise.reject(error);
            });
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  public async needAcceptTheTerm() {
    const portalConfig = localStorage.getItem('portal-config');
    const logService = useLogService();
    if (portalConfig) {
      const portalType = JSON.parse(portalConfig).PORTAL_TYPE;
      const jwt = this.parseJwt();

      if (portalType && portalType === 'SUPPLIER' && jwt) {
        const tncTimeStamp = jwt.extension_tncaccepteddatetime;
        const { serviceModel, country } = jwt;

        if (serviceModel && tncTimeStamp) {
          const response = await axios.get(
            `/lastDateTerm?lang=en&businessModel=${serviceModel.toLowerCase()}&country=${country.toUpperCase()}`
          );
          const lastDateTerm = response.data;

          const { termLastUpdate, policyLastUpdate } = lastDateTerm[serviceModel.toUpperCase()];
          const lastDateAccepted = new Date(tncTimeStamp * 1000).toISOString();

          logService.info('termLastUpdate', termLastUpdate);
          logService.info('lastDateAccepted', lastDateAccepted);
          logService.info('policyLastUpdate', policyLastUpdate);
          return termLastUpdate > lastDateAccepted || policyLastUpdate > lastDateAccepted;
        }
      }
    }
    return false;
  }

  public getMaxTokenLifespan(): number {
    const portalConfig = window.localStorage.getItem(PORTAL_CONFIG) || null;
    if (
      !!portalConfig &&
      !isEmpty(JSON.parse(portalConfig)) &&
      !isEmpty(JSON.parse(portalConfig)['MAX_TOKEN_LIFESPAN'])
    ) {
      return JSON.parse(portalConfig)['MAX_TOKEN_LIFESPAN'];
    }

    return 12 * 60 * 60 * 1000;
  }

  public getSegmentation(): Array<string> {
    const jwt = this.parseJwt();
    const segmentation = jwt?.segmentation;
    if (!Array.isArray(segmentation)) {
      return [];
    }
    return segmentation;
  }

  public getSupportedCountries(): string[] {
    return this.parseJwt()?.supportedCountries ?? [];
  }

  public getVendorId(): string {
    return this.parseJwt()?.vendorId ?? '';
  }
  
  public getIsFederated(): boolean {
    return this.parseJwt()?.isFederated ?? false;
  }
}
export default AuthenticationServiceImp;
