import { createStore, Event } from 'effector';
import FeatureToggleV2, { FeatureToggle } from '../../services/featureToggle/model/FeatureToggleV2';

export type FeatureToggleStoreProps = {
  setToggle: Event<FeatureToggle>;
};

export const createFeatureToggleStoreWithDynamicEvents = ({
  setToggle
}: FeatureToggleStoreProps) => {
  return createStore<FeatureToggleV2>({}).on(setToggle, (state, featureToggle) =>
    Object.assign({}, state, {
      [featureToggle.name]: {
        value: featureToggle.value,
        userId: featureToggle.userId,
        country: featureToggle.country
      }
    })
  );
};
