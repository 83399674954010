import { useCallback } from 'react';
import { UserInfo } from '../../services/featureToggle/model/FeatureToggleV2';
import { useFeatureToggleServiceV2 } from '../useFeatureToggleServiceV2/useFeatureToggleServiceV2';
import { useAuthenticationService } from '../useService/UseService';
import { hasSameInfo } from './helpers';
import { useStoredToggleV2 } from './useStoredToggleV2';

interface FeatureToggleV2Actions {
  isFeatureToggleV2Enabled: () => Promise<boolean>;
  isToggleInfoAvailable: (toggleInfo: UserInfo) => boolean;
}

export function useFeatureToggleV2Actions(
  featureName: string,
  projectName: string
): FeatureToggleV2Actions {
  const service = useFeatureToggleServiceV2(projectName);
  const authentication = useAuthenticationService();
  const zone = authentication.getCountryB2C();
  const userId = authentication.getUserId();
  const country = zone.toUpperCase();
  const currentStoredToggle = useStoredToggleV2(featureName, projectName);

  const isToggleInfoAvailable = useCallback(
    (toggleInfo: UserInfo): boolean =>
      hasSameInfo(toggleInfo, {
        userId,
        country
      }),
    [country, userId]
  );

  const getFeatureToggleValueFromTheStorage = useCallback((): boolean | null => {
    if (!currentStoredToggle) {
      return null;
    }
    const toggleInfo = {
      userId: currentStoredToggle.userId,
      country: currentStoredToggle.country
    };
    return isToggleInfoAvailable(toggleInfo) ? currentStoredToggle.value : null;
  }, [currentStoredToggle, isToggleInfoAvailable]);

  const isFeatureToggleV2Enabled = useCallback(async () => {
    const storedToggleValue = getFeatureToggleValueFromTheStorage();
    if (storedToggleValue !== null) {
      return storedToggleValue;
    }
    return service.isFeatureEnabledAudiences(featureName, userId, {
      country
    });
  }, [country, featureName, getFeatureToggleValueFromTheStorage, service, userId]);

  return {
    isFeatureToggleV2Enabled,
    isToggleInfoAvailable
  };
}
