import axios from 'axios';
import useSwr from 'swr';
import { UserMetadata } from '../..';
import API_URLS from '../../constants/API_URLS';
import { useAuthenticationService } from '../../hooks/useService/UseService';

const initialAxios = axios.create();

const fetcher = (apiPath: string) => initialAxios.get<UserMetadata>(apiPath).then(res => res.data);

export const useUserMetadata = () => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  authentication.enhancedAxios(initialAxios, {
    headers: [
      {
        country: userCountry || ''
      }
    ]
  });
  const authToken = authentication.getAuthHeader();

  const { data, error, mutate } = useSwr(authToken ? API_URLS.USER_METADATA : null, fetcher, {
    dedupingInterval: 5000,
    focusThrottleInterval: 10000,
    revalidateOnFocus: false
  });

  return {
    data,
    isLoading: !error && !data,
    hasError: Boolean(error),
    mutateUserMetadata: mutate
  };
};
