import { z } from 'zod';
import { Sidebar } from '../../store/Sidebar/SidebarService.types';

const baseSchema = {
  id: z.string(),
  title: z.string(),
  icon: z.function().returns(z.function()),
  path: z.string(),
  onPageChange: z.function().optional()
};

const itemSchema = z.object(baseSchema);

const utilsItemSchema = z.object({
  ...baseSchema,
  target: z.enum(['_blank', '_self']).optional()
});

const sidebarSchema = z.object({
  items: z
    .array(itemSchema)
    .max(10, { message: '[useSidebar]: It is not allowed to send more than 10 items.' }),
  utils: z
    .array(utilsItemSchema)
    .max(3, { message: '[useSidebar]: It is not allowed to send more than 3 items.' })
});

export const validateSidebarProps = (sidebarProps: Sidebar): boolean => {
  const validation = sidebarSchema.safeParse(sidebarProps);

  if (!validation.success) {
    const { issues } = validation.error;
    issues.forEach(issue => {
      console.error(
        issue.code === 'invalid_type'
          ? `[useSidebar]: It was expected type ${issue.expected}, but it was found ${
              issue.received
            } on ${issue.path.join('')}`
          : issue.message
      );
    });
  }

  return validation.success;
};
