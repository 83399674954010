import React from 'react';
import { Helmet } from 'react-helmet';

export const DocumentTitle = ({ children }: { children: string }) => {
  return (
    <Helmet titleTemplate="BEES One - %s">
      <title>{children}</title>
    </Helmet>
  );
};
