import { createEvent, createStore } from 'effector';
import { Sidebar } from './SidebarService.types';

export const setSidebar = createEvent<Sidebar>('setSidebar');
export const restoreToDefault = createEvent('restoreToDefault');

const initialState = {
  items: [],
  utils: []
};

export const sidebarStore = createStore<Sidebar>(initialState)
  .on(setSidebar, (state, sidebarItems) => Object.assign({}, state, sidebarItems))
  .reset(restoreToDefault);
