import { useStoreMap } from 'effector-react';
import { useMemo } from 'react';
import { useFeatureToggleService } from '../useService/UseService';

type FeatureToggleValue = [boolean, boolean]

export function useFeatureToggleAsync(featureName: string, zone?: string): FeatureToggleValue {
  const featureToggle = useStoreMap({
    store: useFeatureToggleService().getStore(),
    keys: [featureName],
    fn: (features, [name]) => (features[name] ?? null)
  });

  const value = useMemo<FeatureToggleValue>(() => {
    if (typeof featureToggle === 'string' && zone) {
      return [featureToggle.includes(zone), false];
    }
  
    return [featureToggle === true, featureToggle === null];
  }, [featureToggle]);

  return value;
}
