import { createEvent } from 'effector';
import { FeatureToggle } from '../../services/featureToggle/model/FeatureToggleV2';

export const getFeatureToggleEvents = () => {
  const setToggle = createEvent<FeatureToggle>('setToggle');

  return {
    setToggle
  };
};
