import { useStoreMap } from 'effector-react';
import { usePermissionService } from '../useService/UseService';

/**
 * @deprecated This function is deprecated, consider update to useHasPermission: {@link http://ab-inbev.atlassian.net/wiki/spaces/SP/pages/3128236378/useHasPermission}
 */
export function hasPermission(scopes: string | Array<string>): boolean {
  console.warn(
    `[DEPRECATED]: hasPermission will be deprecated soon. Consider update to useHasPermission: https://ab-inbev.atlassian.net/wiki/spaces/SP/pages/3128236378/useHasPermission`
  );

  const scopesFromStore: Array<string> = useStoreMap({
    store: usePermissionService().getStore(),
    keys: [scopes],
    fn: (state: { scopes: Array<string> }) => state.scopes
  });

  return usePermissionService().hasScopes(scopesFromStore, scopes);
}
