import { useStoreMap } from 'effector-react';
import { useFeatureToggleService } from '../useService/UseService';

export function useFeatureToggle(featureName: string, zone?: string): boolean {
  const featureToggle = useStoreMap({
    store: useFeatureToggleService().getStore(),
    keys: [featureName],
    fn: (features, [name]) => (features[name] ?? null)
  });

  if (typeof featureToggle === 'string' && zone) {
    return featureToggle.includes(zone);
  }

  return featureToggle === true;
}
