enum SegmentKey {
  BEED_BR = 'BEED_SEGMENT_KEY_BR',
  BEED_AR = 'BEED_SEGMENT_KEY_AR',
  BEED_PY = 'BEED_SEGMENT_KEY_PY',
  BEED_CA = 'BEED_SEGMENT_KEY_CA',
  MEMBERSHIP = 'MEMBERSHIP_SEGMENT_KEY',
  TLP = 'TLP_SEGMENT_KEY',
  SUPPLIER = 'SUPPLIER_SEGMENT_KEY',
  ADMIN_PORTAL = 'ADMIN_PORTAL_SEGMENT_KEY',
  ADMIN_PORTAL_VENDOR = 'ADMIN_PORTAL_VENDOR_SEGMENT_KEY'
}

export default SegmentKey;
