import { Store } from 'effector';
import { useAuthenticationService } from '../../hooks/useService/UseService';
import * as permissionEvents from '../../store/Permission/PermissionEvents';
import permissionStore from '../../store/Permission/PermissionStore';
import PermissionService from './PermissionService';
import Scope from './models/Scope';

class PermissionServiceImp implements PermissionService {
  private store: Store<Scope>;
  private events;

  constructor() {
    this.store = permissionStore;
    this.events = permissionEvents;

    this.events.setScopes(this.getScopesFromJwt());
  }

  public getScopes(): string[] {
    return this.getScopesFromStore();
  }

  private getScopesFromStore(): Array<string> {
    return this.store.map(({ scopes }) => scopes).getState();
  }

  public getScopesFromJwt(): Array<string> {
    const authenticationService = useAuthenticationService();
    return authenticationService.getUserScopes();
  }

  private hasWriteScope(scopesFromStore: Array<string>, scope: string): boolean {
    return scopesFromStore.includes(scope);
  }

  public hasOneOfScopes(scopes: Array<string> | string): boolean {
    const scopesFromStore = this.getScopesFromStore();

    if (typeof scopes === 'string') return scopesFromStore.includes(scopes);

    return scopes.some(scope => scopesFromStore.includes(scope));
  }

  public hasScopes(scopesFromStore: Array<string>, userScope: Array<string> | string): boolean {
    if (Array.isArray(userScope)) {
      const canAccess: Array<boolean> = userScope.map(scope => {
        return this.hasWriteScope(scopesFromStore, scope);
      });

      return !canAccess.includes(false);
    }

    return this.hasWriteScope(scopesFromStore, userScope);
  }

  public has(userScope: Array<string> | string): boolean {
    const scopesFromStore = this.getScopesFromStore();

    return this.hasScopes(scopesFromStore, userScope);
  }

  public hasScopesToApp(appName: string): boolean {
    const scopesFromStore = this.getScopesFromStore();

    return scopesFromStore.some(scope => {
      const appNameFromStoreScope = scope.split('.')[0];

      return appNameFromStoreScope === appName;
    });
  }

  public getStore(): Store<Scope> {
    return this.store;
  }

  public addScopes(scopes: Array<string>) {
    this.events.setScopes(scopes);
  }
}

export default PermissionServiceImp;
