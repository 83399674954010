import { useEffect } from 'react';
import { restoreToDefault, setTheme } from '../../store/HubTheme/HubThemeService';
import { HubTheme, validateTheme } from './helpers';

export const useHubTheme = (theme: HubTheme) => {
  useEffect(() => {
    const isValidTheme = validateTheme(theme);

    if (isValidTheme) {
      setTheme(theme);
    }

    return () => {
      restoreToDefault();
    };
  }, [theme]);
};
