import * as PubSub from 'pubsub-js';
import { v4 as uuidv4 } from 'uuid';
import Snackbar, { SnackbarSubscription } from './model/Snackbar';
import SnackbarService from './SnackbarService';

class SnackbarServiceImp implements SnackbarService {
  private TOPIC_NAME = 'SNACKBAR';
  public notify(snackbar: Snackbar): void {
    PubSub.publish(this.TOPIC_NAME, snackbar);
  }
  public subscribe(callback: (snackbar: SnackbarSubscription) => void): any {
    return PubSub.subscribe(this.TOPIC_NAME, (_: string, data: SnackbarSubscription) =>
      callback({ ...data, id: uuidv4() })
    );
  }
  public unsubscribe(callback: (snackbar: SnackbarSubscription) => void): void {
    PubSub.unsubscribe(callback);
  }
}

export default SnackbarServiceImp;
