import { useAuthenticationService } from '../../hooks/useService/UseService';
import { fetchUserMetadata } from '../api_services/userMetadata/userMetadataService';
import { Country } from './headerConfig.types';

export const sortString = (a?: string, b?: string) => ((a || '') < (b || '') ? -1 : 1);

export const fetchVendors = async (selectedCountry: Country) => {
  const userMetadata = await fetchUserMetadata(selectedCountry);
  if (userMetadata?.vendors) return userMetadata.vendors;
  return [];
};

export const getCountriesIntersection = async (newCountries: Country[]): Promise<string[]> => {
  const userCountry = useAuthenticationService().getUserCountry();
  const userMetadata = await fetchUserMetadata(userCountry);

  if (!userMetadata?.supportedCountries) return [];

  return newCountries.filter(newCountry => userMetadata?.supportedCountries?.includes(newCountry));
};
