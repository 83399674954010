import axios from 'axios';
import useSwr from 'swr';
import { useAuthenticationService } from '../useService/UseService';

export type Error = {
  statusCode: number;
  error: string;
  message: string;
};

const initialAxios = axios.create();
const authentication = useAuthenticationService();

export const API_URL = '/api/v1/admin-portal-shared-bff/audit-log/operations';

authentication.enhancedAxios(initialAxios);

const fetcher = (apiPath: string) =>
  initialAxios
    .get<Array<string>>(apiPath)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

export function useGetOperations(shouldFetch: boolean = true) {
  const { data, error, isValidating } = useSwr(shouldFetch ? API_URL : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 3
  });

  return {
    data,
    isLoading: isValidating,
    hasError: Boolean(error),
    error
  };
}
