enum Env {
  QA = 'QA',
  DEV = 'DEV',
  E2E = 'E2E',
  UAT = 'UAT',
  SIT = 'SIT',
  PROD = 'PROD',
  LOCALHOST = 'LOCALHOST'
}

export default Env;
