import axios from 'axios';
import queryString from 'query-string';
import useSwr from 'swr';
import { useAuthenticationService } from '../../hooks/useService/UseService';
import { AuditLogGetParams, AuditLogGetSuccessSchema, OnError } from './useGetAuditLogs.types';

const initialAxios = axios.create();
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

const API_URL = '/api/v1/admin-portal-shared-bff/audit-log';

authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      country: userCountry ?? ''
    }
  ]
});

const fetcher = (onError?: OnError) => {
  return (apiPath: string) => {
    return initialAxios
      .get<AuditLogGetSuccessSchema>(apiPath)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (error.response && typeof onError === 'function') {
          onError(error.response.data);
        }

        throw error;
      });
  };
};

export function useGetAuditLogs(
  queryParams: AuditLogGetParams,
  shouldFetch: boolean = true,
  onError?: OnError
) {
  const queryParameters = queryString.stringify(queryParams);

  const AUDIT_LOG_ENDPOINT = `${API_URL}?${queryParameters}`;

  const { data, error, isValidating } = useSwr(
    shouldFetch ? AUDIT_LOG_ENDPOINT : null,
    fetcher(onError),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 2
    }
  );

  return {
    data,
    isLoading: isValidating,
    hasError: Boolean(error),
    error
  };
}
