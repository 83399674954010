import { useAuthenticationService } from '../../hooks/useService/UseService';
import isValidStringJson from '../../utils/isValidStringJson/isValidStringJson';
import AnalyticsService, { AnonymousId } from './AnalyticsService';

class AnalyticsServiceImp implements AnalyticsService {
  private initialAnalytics: SegmentAnalytics.AnalyticsJS;
  private anonymousId: AnonymousId = null;
  private lastSegmentKey: string | null = null;

  constructor() {
    this.init();
    this.initialAnalytics = window.analytics;
    this.anonymousId = this.getAnonymousId();
  }

  private init(): void {
    if (!window.analytics) {
      const scriptElement = document.createElement('script');
      const segmentSnippet = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.2";
        }}();`;

      scriptElement.textContent = segmentSnippet;
      document.body.appendChild(scriptElement);
    }
  }

  private getAnonymousId(): AnonymousId {
    const anonymousId = localStorage.getItem('ajs_anonymous_id');

    if (anonymousId && typeof anonymousId === 'string') {
      return isValidStringJson(anonymousId) ? JSON.parse(anonymousId) : anonymousId;
    }
    return null;
  }

  private setAnonymousId(): void {
    if (this.anonymousId) {
      window.analytics.setAnonymousId(this.anonymousId);
    }
  }

  public load(segmentkey: string): void {
    if (segmentkey !== this.lastSegmentKey) {
      window.analytics = this.initialAnalytics;
      window.analytics.load(segmentkey);

      this.setAnonymousId();
      this.lastSegmentKey = segmentkey;
      this.identify({});
    }
  }

  public identify<Traits>(traits: Traits): void {
    const userId = useAuthenticationService().getUserId();
    window.analytics.identify(
      userId,
      traits!,
      this.anonymousId ? { anonymousId: this.anonymousId } : undefined
    );
  }
}

export default AnalyticsServiceImp;
