import optimizelySDK, { enums, UserAttributes } from '@optimizely/optimizely-sdk';
import { useEnvService } from '../../hooks/useService/UseService';
import { setValue, turnOff, turnOn } from '../../store/FeatureToggle/FeatureToggleEvents';
import createFeatureToggleStore from '../../store/FeatureToggle/FeatureToggleStore';
import Env from '../env/model/Env';
import FeatureToggleService from './FeatureToggleService';

const MOCKED_FEATURE_TOGGLES = 'mocked-feature-toggles';
class FeatureToggleOptmizelyServiceImp implements FeatureToggleService {
  private optimizely = optimizelySDK.createInstance({
    datafileOptions: {
      autoUpdate: true
    },
    logLevel: 4,
    sdkKey: this.getAuthorizationKey()
  });

  private store = createFeatureToggleStore();

  private env = Env.DEV;

  public constructor() {
    this.getMockedFeatureToggles();
    this.initOptimizely();
    this.env = useEnvService().getEnv();

    if (this.env === 'LOCALHOST') {
      this.env = Env.DEV;
    }
  }

  public async initOptimizely() {
    await this.optimizely.onReady();
    this.updateToggles(this.optimizely, this.env);
    this.optimizely.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.OPTIMIZELY_CONFIG_UPDATE,
      () => this.updateToggles(this.optimizely, this.env)
    );
  }

  public getStore() {
    return this.store;
  }

  public loadToggles(): void {
    //do nothing
  }

  public mockFeatureToggle(featureToggleName: string, mockedStatus: boolean | string) {
    this.updateToggle(featureToggleName, mockedStatus);
  }

  public isFeatureEnabled(featureToggleName: string, zone?: string): boolean {
    const toggleValue = this.store.getState()[featureToggleName];
    if (typeof toggleValue === 'string' && zone) {
      const toggleZones = toggleValue.split(",").map(toggleZone => toggleZone.trim());
      return toggleZones.includes(zone);
    }

    return toggleValue === true;
  }

  public isFeatureEnabledAudiences(
    featureToggleName: string,
    userId: string,
    attributes: UserAttributes
  ): boolean {
    return this.optimizely.isFeatureEnabled(featureToggleName, userId, attributes);
  }

  private updateToggle(featureToggleName: string, isOn: boolean | string) {
    if (typeof isOn === 'string') {
      setValue({ toggleName: featureToggleName, value: isOn });
    } else {
      if (isOn) {
        turnOn(featureToggleName);
      } else {
        turnOff(featureToggleName);
      }
    }
  }

  private updateToggles(optimizely, env: string) {
    const toggles = optimizely.getOptimizelyConfig();

    if (toggles) {
      Object.keys(toggles.featuresMap).forEach((key: string) => {
        if (!toggles?.featuresMap[key]?.variablesMap?.[env.toLowerCase()]) {
          return;
        }
        let toggleValue = toggles?.featuresMap[key]?.variablesMap?.[env.toLowerCase()]?.value;
        if (['on', 'off'].includes(toggleValue)) {
          toggleValue = toggleValue === 'on';
        }

        this.updateToggle(toggles.featuresMap[key].key, toggleValue);
      });
    }
  }

  private getAuthorizationKey(): string {
    return useEnvService().getOptimizelyKey();
  }

  private getMockedFeatureToggles() {
    const mockedFeatures = JSON.parse(window.localStorage.getItem(MOCKED_FEATURE_TOGGLES) || '{}');
    Object.keys(mockedFeatures).forEach((key: string) => {
      this.updateToggle(key, mockedFeatures[key] === 'on');
    });
  }

  public getInstance(): optimizelySDK.Client {
    return this.optimizely;
  }
}

export default FeatureToggleOptmizelyServiceImp;
