// tslint:disable:no-console
import axios, { AxiosResponse } from 'axios';
import { useAuthenticationService, useEnvService } from '../../hooks/useService/UseService';
import LogService from './LogService';
import { SeverityLevel } from './LogService.types';

class LogServiceImp implements LogService {
  public debug(message: string): void {
    this.sendLog(SeverityLevel.DEBUG, message);
    newrelic.addPageAction(SeverityLevel.DEBUG, { message });
    this.verifyEnvironment(() => {
      console.debug(message);
    });
  }

  public info(message: string): void {
    this.sendLog(SeverityLevel.INFO, message);
    newrelic.addPageAction(SeverityLevel.INFO, { message });
    this.verifyEnvironment(() => {
      console.info(message);
    });
  }

  public warn(message: string): void {
    this.sendLog(SeverityLevel.WARNING, message);
    newrelic.addPageAction(SeverityLevel.WARNING, { message });
    this.verifyEnvironment(() => {
      console.warn(message);
    });
  }

  public error(message: string): void {
    this.sendLog(SeverityLevel.ERROR, message);
    newrelic.noticeError(message);
    this.verifyEnvironment(() => {
      console.error(message);
    });
  }

  private verifyEnvironment(consoleLog: (...data: Array<any>) => void) {
    const isDev = useEnvService().getEnv() === 'DEV';
    if (isDev) {
      consoleLog();
    }
  }

  private async sendLog(level: SeverityLevel, message: string): Promise<any> {
    axios
      .post<any, AxiosResponse>(
        '/log',
        {
          data: [message],
          level
        },
        {
          headers: {
            Authorization: useAuthenticationService().getAuthHeader()
          }
        }
      )
      .catch(err => {
        console.error('Unable to send logs -', err);
      });
  }
}
export default LogServiceImp;
