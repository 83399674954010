import { useEffect, useState } from 'react';
import { useFeatureToggleService } from '../useService/UseService';

export function useIsFeatureToggleServiceReady(): boolean {
  const featureToggleService = useFeatureToggleService();
  const [response, setResponse] = useState<boolean>(false);

  useEffect(() => {
    featureToggleService
      .getInstance()
      .onReady()
      .then(result => {
        setResponse(result.success);
      });
  }, []);

  return response;
}
