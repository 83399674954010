import { createStore, Event } from 'effector';
import FeatureToggles from '../../services/featureToggle/model/FeatureToggles';
import { setValue, turnOff, turnOn } from './FeatureToggleEvents';

export type FeatureToggleStoreProps = {
  turnOn: Event<string>;
  turnOff: Event<string>;
  setValue: Event<{ toggleName: string; value: string }>;
};

function createFeatureToggleStore() {
  return createStore<FeatureToggles>({})
    .on(setValue, (state, { toggleName, value }) =>
      Object.assign({}, state, { [toggleName]: value })
    )
    .on(turnOn, (state, toggleName) => Object.assign({}, state, { [toggleName]: true }))
    .on(turnOff, (state, toggleName) => Object.assign({}, state, { [toggleName]: false }));
}

export default createFeatureToggleStore;
