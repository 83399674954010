import { z } from 'zod';

export const VendorSchema = z.object({
  id: z.optional(z.string()),
  country: z.optional(z.string()),
  displayName: z.optional(z.string()),
  serviceModel: z.optional(z.string()),
  governmentId: z.optional(z.string()),
  abiVendorId: z.optional(z.string())
});

export const BreadcrumbItemSchema = z.object({
  label: z.string(),
  path: z.string(),
  onClick: z.optional(z.function()),
  isCurrentPage: z.boolean()
});

export const BreadCrumbConfigSchema = z
  .object({
    homePath: z.string(),
    items: z.array(BreadcrumbItemSchema)
  })
  .strict();

export const CountrySchema = z.string();

export const ButtonOptionSchema = z
  .object({
    onClick: z.optional(z.function()),
    label: z.string(),
    icon: z.function()
  })
  .strict();

export const DropdownOptionSchema = z
  .object({
    onClick: z.optional(z.function()),
    label: z.string(),
    icon: z.function(),
    options: z.optional(z.array(ButtonOptionSchema).max(3))
  })
  .strict();

const OptionalButton = z.union([DropdownOptionSchema, ButtonOptionSchema]);

export const OptionalButtons = z.array(z.optional(OptionalButton)).max(2);

export const AppHeaderConfigSchema = z
  .object({
    countryOptions: z.optional(z.array(CountrySchema)),
    breadcrumbConfig: z.optional(BreadCrumbConfigSchema),
    vendorSelect: z.optional(z.boolean()),
    countrySelect: z.optional(z.boolean()),
    loadingVendors: z.boolean(),
    vendorOptions: z.optional(z.array(VendorSchema)),
    selectedCountry: z.optional(CountrySchema),
    selectedVendor: z.optional(z.string()),
    optionalButtons: z.optional(OptionalButtons)
  })
  .refine(({ vendorSelect, countrySelect }) => !(vendorSelect && !countrySelect), {
    message: 'In order to use the vendorSelect, the countrySelect config must also be true',
    path: ['vendorSelect']
  });

export const AppHeaderConfigPayloadSchema = z.object({
  countryOptions: z.optional(z.array(CountrySchema)),
  breadcrumbConfig: z.optional(BreadCrumbConfigSchema),
  vendorSelect: z.optional(z.boolean()),
  countrySelect: z.optional(z.boolean()),
  optionalButtons: z.optional(OptionalButtons),
  pageTitle: z.optional(z.string())
});
// .refine(({ vendorSelect, countrySelect }) => !(vendorSelect && !countrySelect), {
//   message: 'In order to use the vendorSelect, the countrySelect config must also be true'
// })
// .refine(({ countrySelect, countryOptions }) => countryOptions?.length === 0 && countrySelect, {
//   message: 'If using the countrySelect there must be at least one countryOption set'
// });
