import { z } from 'zod';

const themeSchema = z.enum([
  'partner',
  'grow',
  'care',
  'deliver',
  'force',
  'lift',
  'customer',
  'tadaPrimary',
  'tadaSecondary'
]);

export type HubTheme = z.infer<typeof themeSchema>;

export const validateTheme = (theme: HubTheme): boolean => {
  const validation = themeSchema.safeParse(theme);

  if (!validation.success) {
    console.error(
      '[useTheme] The received theme is not valid, default theme was applied. Please check the passed value.'
    );
  }
  return validation.success;
};
