import FeatureToggleOptmizelyServiceV2Imp from '../../services/featureToggle/featureToggleOptimizelyServiceV2/FeatureToggleOptmizelyServiceV2Imp';

export function createFeatureToggleServiceV2(projectName: string, sdkKey: string): void {
  const FEATURE_NAME = 'FeatureToggleOptimizelyV2';

  if (!window.services) {
    window.services = {};
  }

  if (!window.services[FEATURE_NAME]) {
    window.services[FEATURE_NAME] = {};
  }

  if (!window.services[FEATURE_NAME][projectName]) {
    window.services[FEATURE_NAME][projectName] = new FeatureToggleOptmizelyServiceV2Imp(sdkKey);
  } else {
    console.error(
      `[FeatureToggleOptimizelyV2]: This instance with projectName: ${projectName} is already created`
    );
  }
}
