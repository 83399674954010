/**
 * This function is intended to fix a problem within MUI
 */
export function generateIncrementalCounter(prefix: string, initialCounter?: number) {
  let storedCounter = initialCounter ?? 0;

  return () => {
    if (process.env.NODE_ENV !== 'production') {
      if (storedCounter >= 1e10) {
        console.warn(
          `You might have a memory leak. The createGenerateClassname internal counter is not supposed to grow that much.`
        );
      }
    }
    const counter = `${prefix}-${storedCounter}`;
    storedCounter += 1;
    return counter;
  };
}
